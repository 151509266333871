import { useLazyQuery } from "@vue/apollo-composable";
import {
  getOpenAmount,
  getUserBalance,
  payoutRequestMutation,
} from "~/graphql/wallet";
import { useWalletStore } from "~/stores/wallet";
import useSharedApolloService from "./sharedApolloService";

export default () => {
  const walletStore = useWalletStore();

  const sharedApolloService = useSharedApolloService();

  const getUserBalanceQuery = useLazyQuery(getUserBalance);

  getUserBalanceQuery.onResult((result) => {
    if (result.loading || !result.data?.getWallet) return;
    walletStore.update(result.data.getWallet);
  });

  const getOpenAmountQuery = useLazyQuery(getOpenAmount);

  getOpenAmountQuery.onResult((result) => {
    if (result.loading || !result.data?.getOpenPayoutAmount) return;
    walletStore.update(result.data.getOpenPayoutAmount);
  });

  const payoutRequest = sharedApolloService.useSharedMutation(
    payoutRequestMutation
  );

  return {
    walletStore,

    // Apollo
    getUserBalanceQuery,
    getOpenAmountQuery,
    payoutRequest,
  };
};
