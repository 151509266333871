import { graphql } from "~/gql";

export const getUserBalance = graphql(`
  query getUserBalance {
    getWallet {
      status {
        success
        message
        identifier
      }
      wallet {
        availableAmount
        totalAmount
        freezeAmount
      }
    }
  }
`);

export const getOpenAmount = graphql(`
  query getOpenPayoutAmount {
    getOpenPayoutAmount {
      status {
        success
        message
        identifier
      }
      amount
    }
  }
`);

export const payoutRequestMutation = graphql(`
  mutation payoutRequest($input: PayoutRequestInput!) {
    payoutRequest(input: $input) {
      status {
        success
        message
        identifier
      }
    }
  }
`);
